import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['tab', 'tab-container', 'content', 'dot', 'code']

  updateSelectedTab(tabId) {
    this.updateSelectedTabElement(tabId)
    this.updateSelectedTabContent(tabId)
  }

  connect() {
    console.log('free connect', this.element)

    this.updateSelectedTab('benefits')
  }

  viewOfferBox() {
    this.codeTarget.focus()
  }

  // now, if I could just combine these a bit so they aren't so repetitive
  // surely there's way
  updateSelectedTabElement(tabId) {
    this.tabTargets.forEach((tab) => {
      if (tab.getAttribute('tab-id') == tabId) {
        tab.classList.add('selected')
        tab.classList.add('font-bold')
        tab.classList.remove('opacity-50')
        tab.classList.add('opacity-100')
      } else {
        tab.classList.remove('selected')
        tab.classList.remove('font-bold')
        tab.classList.remove('opacity-100')
        tab.classList.add('opacity-50')
      }
    })
  }

  updateSelectedTabContent(tabId) {
    this.contentTargets.forEach((section) => {
      if (section.getAttribute('tab-id') == tabId) {
        section.classList.add('flex')
        section.classList.remove('hidden')
      } else {
        section.classList.remove('flex')
        section.classList.add('hidden')
      }
    })
  }

  selectTab(e) {
    clearInterval(this.intervalId)
    this.updateSelectedTab(e.currentTarget.getAttribute('tab-id'))
  }
}
