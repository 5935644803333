import {Controller} from "@hotwired/stimulus"

/*
 * I'm not in love with this controller; it's mostly generic, but it uses a specific selector,
 * when it ought to use Stimulus targets. Also, Hey.com has some pretty interesting approaches
 * in their popup controller to look at for clickoutside focus and bluring. It appears they
 * use a blur effect in the data-turbo-action attribute, or something along that line.
 */

/*
 * I'm not in love with this controller; it's mostly generic, but it uses a specific selector,
 * when it ought to use Stimulus targets. Also, Hey.com has some pretty interesting approaches
 * in their popup controller to look at for clickoutside focus and bluring. It appears they
 * use a blur effect in the data-turbo-action attribute, or something along that line.
 */

const selector = "details[data-type=\"flyout\""

const closeDetails = (el) => el.removeAttribute("open")

const hideClickOutsideDetailsListener = (detailsTarget) => {
  const clickOutsideDetailsListener = (event) => {
    const eventTarget = event.target
    const closestDetails = eventTarget.closest(selector)

    if (!closestDetails && detailsTarget.open) {
      closeDetails(detailsTarget)
      removeClickOutsideDetailsListener()
    }
  }

  const removeClickOutsideDetailsListener = () => {
    document.removeEventListener("click", clickOutsideDetailsListener)
  }

  document.addEventListener("click", clickOutsideDetailsListener)
}

function closeAllFlyouts(exceptEl) {
  document.querySelectorAll(selector).forEach(el => {
    if (exceptEl !== el) {
      closeDetails(el)
    }
  })
}

export default class extends Controller {
  connect() {
    console.log("flyout connect", this.element)

    // Close all flyouts (for back and forward navigation in the browser)
    closeAllFlyouts()
  }

  closeAllFlyouts() {
    closeAllFlyouts()
  }

  /**
   * Ensures the flyout can be closed by clicking away.
   * @param event
   */
  toggleFlyout(event) {
    const flyoutTarget = event.currentTarget
    closeAllFlyouts(flyoutTarget)

    if (flyoutTarget.open) {
      hideClickOutsideDetailsListener(flyoutTarget)
    }
  }
}