import {Controller} from "@hotwired/stimulus"
import {default as _set} from "lodash/set"
import {default as _get} from "lodash/get"

const localStateKey = "checklists"

export default class extends Controller {
  static targets = ["todo"]

  connect() {
    console.log("checklist connect", this.element)

    if (this.inLocalMode) {
      this.renderLocalTodos()
    }
  }

  get inLocalMode() {
    return this.element.dataset.localMode !== undefined
  }

  get localState() {
    const localState = localStorage.getItem(localStateKey)
    return typeof localState === "string" ? JSON.parse(localState) : {} // => State has a shape of e.g. {"1": {"todos" {"a": {checked: true}}}}
  }

  setLocalState(path, value) {
    let state = this.localState
    _set(state, path, value)
    localStorage.setItem(localStateKey, JSON.stringify(state))
  }

  _localTodoPath(checklistId, todoId) {
    return ["checklist-" + checklistId, "todos", "todo-" + todoId, "checked"]
  }

  localTodoPath(todoEl) {
    return this._localTodoPath(todoEl.dataset.checklistId, todoEl.value)
  }

  isLocalTodoChecked(todoEl) {
    return _get(this.localState, this.localTodoPath(todoEl)) === true
  }

  renderLocalTodos() {
    this.todoTargets.forEach(todo => {
      if (this.isLocalTodoChecked(todo)) {
        todo.setAttribute("checked", "")
      }
    })
  }

  toggleTodoRemote(el) {
    el.currentTarget.form.requestSubmit()
  }

  toggleTodoLocal(el) {
    const todo = el.currentTarget
    this.setLocalState(this.localTodoPath(todo), !this.isLocalTodoChecked(todo))
    todo.form.requestSubmit()
  }

  toggleTodo(el) {
    if (!this.inLocalMode) {
      this.toggleTodoRemote(el)
    } else {
      this.toggleTodoLocal(el)
    }
  }
}