import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static values = { id: String }

    change(event) {
        let questionType = event.target.selectedOptions[0].value

        fetch(`/questions/question-type-changed?id=${this.idValue}&type=${questionType}`)
        .then(response => response.text())
        .then(html => Turbo.renderStreamMessage(html))
    }
}