import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["handle", "link"]

  connect() {
    console.log("recruiting connect", this.element)
  }

  toggle(event) {
    this.handleTarget.classList.toggle("hidden")
  }
}
