import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("upvote connect", this.element)
  }

  vote(event) {
    this.element.requestSubmit()
    event.preventDefault()
  }
}