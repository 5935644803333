import {Controller} from "@hotwired/stimulus"

const INDEX_DEFAULT = 0

export default class extends Controller {
  static targets = ["menu", "option", "input"]
  static values = {
    index: {type: Number, default: INDEX_DEFAULT}
  }

  connect() {
    console.log("keyboard-list connect", this.element)

    this.listen()
  }

  menuTargetConnected() {
    this.addDefaultClasses()
    this._indexValueChanged(this.indexValue, INDEX_DEFAULT)
  }

  optionTargetConnected(target) {
    target.addEventListener("click", ((e) => {
      this.setIndex(this.optionTargets.indexOf(target))
      this.selectItem()
    }).bind(this))
  }

  /**
   * Listens for keystrokes to the main input target.
   */
  listen() {
    const self = this
    const input = self.inputTarget

    // Listen for keystroke events
    input.addEventListener('keydown', e => {
      switch (e.key) {
        case "ArrowDown":
          self.incIndex()
          e.preventDefault() // Prevent cursor from moving to the end of the results
          break
        case "ArrowUp":
          self.decIndex()
          e.preventDefault()
          break
        case "Enter":
          self.selectItem(e)
          break
      }
    })
  }

  /**
   * Navigate to the selected item in the list.
   */
  selectItem() {
    this.element.dispatchEvent(new CustomEvent("handleSelectResult", {
      detail: { // The `detail` key will carry the payload for your event
        target: this.selectedOptionTarget
      },
      bubbles: true // Necessary to make the event bubble to other controllers upstream in the DOM.
    }))
  }

  /**
   * Needed because its the non-"_" name is used in the Stimulus lifecycle.
   * @param value
   * @param previousValue
   * @private
   */
  _indexValueChanged(value, previousValue) {
    this.clearHighlight(previousValue)
    this.drawHighlight()
    this.refocusMenu()
  }

  /**
   * Fires when `index` is updated.
   * @param value
   * @param previousValue
   */
  indexValueChanged(value, previousValue) {
    this._indexValueChanged(value, previousValue)
  }

  /**
   * Returns the currently selected option target.
   * @returns {*}
   */
  get selectedOptionTarget() {
    return this.optionTargets[this.indexValue]
  }

  /**
   * Clears the CSS highlighting the option
   */
  clearHighlight(index) {
    this.optionTargets[index]?.classList.remove("keyboard-list-item-active")
  }

  /**
   * Draws a new CSS highlight for the option
   */
  drawHighlight() {
    this.selectedOptionTarget?.classList.add("keyboard-list-item-active")
  }

  addDefaultClasses() {
    this.optionTargets.forEach((target) => {
      target.classList.add("transition-all", "hover:ease-on-hover", "hover:bg-gray-8")
    })
  }

  /**
   * Handler for the resetIndex event used in other controllers
   */
  resetIndex() {
    this.indexValue = INDEX_DEFAULT
  }

  /**
   * Mutate the index by a set number.
   * @param value
   */
  setIndex(value) {
    this.indexValue = value
  }

  /**
   * Mutate the index up one until it reaches a ceiling equal to the length of the list.
   */
  incIndex() {
    this.indexValue = Math.min(this.optionTargets.length - 1, this.indexValue + 1)
  }

  /**
   * Mutate the index down one until it reaches the beginning of the list.
   */
  decIndex() {
    this.indexValue = Math.max(INDEX_DEFAULT, this.indexValue - 1)
  }

  /**
   * Ensure that the currently selected menu item remains visible within the user's view
   * by scrolling to it.
   */
  refocusMenu() {
    if (this.hasMenuTarget) {
      const container = this.menuTarget
      const el = this.selectedOptionTarget

      if (el && !el.dataset.skipRefocusMenu) {
        let cTop = container.scrollTop
        let cBottom = cTop + container.clientHeight

        // Determine element top and bottom
        let eTop = el.offsetTop
        let eBottom = eTop + el.clientHeight

        // Check if out of view
        if (eTop < cTop) {
          container.scrollTop -= (cTop - eTop)
        } else if (eBottom > cBottom) {
          container.scrollTop += (eBottom - cBottom)
        }
      }
    }
  }
}
