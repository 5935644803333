import { Controller } from '@hotwired/stimulus'

function uuid() {
  var dt = new Date().getTime()
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0
      dt = Math.floor(dt / 16)
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
    }
  )
  return uuid
}

const clientUUID = uuid()

function registerVisitorEvent(params) {
  const token = document.querySelector('meta[name="csrf-token"]')?.['content']
  fetch('/register-visitor-event', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-CSRF-TOKEN': token,
    },
    body: new URLSearchParams(params),
  })
}

export default class extends Controller {
  idleTime = 0
  lastActionTimestamp = null
  loadTimestamp = null

  connect() {
    console.log('visitor events connect', this.element)

    const baseVisitorEventInfo = {
      'visitor-event.form/uri': window.location.pathname,
      'visitor-event.form/user-agent': window.navigator.userAgent,
      'visitor-event.form/referer': document.referrer,
      'visitor-event.form/client-uuid': clientUUID,
    }
    const controller = this

    function registerLoad(e) {
      controller.loadTimestamp = controller.lastActionTimestamp = Date.now()

      registerVisitorEvent({
        ...baseVisitorEventInfo,
        'visitor-event.form/event-type': 'load',
        'visitor-event.form/metadata': JSON.stringify({
          'load-timestamp': controller.loadTimestamp,
          lang: window.navigator && window.navigator.language,
          timezone: Intl && Intl.DateTimeFormat().resolvedOptions().timeZone,
        }),
      })
    }

    function registerLeave(e) {
      const unloadTimestamp = Date.now()
      updateIdleTime()

      registerVisitorEvent({
        ...baseVisitorEventInfo,
        'visitor-event.form/event-type': 'leave',
        'visitor-event.form/metadata': JSON.stringify({
          'unload-timestamp': unloadTimestamp,
          'time-on-page-ms': unloadTimestamp - controller.loadTimestamp,
          'idle-time-ms': controller.idleTime,
        }),
      })
    }

    function updateIdleTime() {
      const timeDiff = Date.now() - controller.lastActionTimestamp
      if (timeDiff > 60000) {
        controller.idleTime += timeDiff
      }
      controller.lastActionTimestamp = Date.now()
    }

    registerLoad()

    window.addEventListener('beforeunload', registerLeave)
    window.addEventListener('locationchange', registerLeave)

    document.addEventListener('mousemove', updateIdleTime, true)
    document.addEventListener('mousedown', updateIdleTime, true)
    document.addEventListener('touchstart', updateIdleTime, true)
    document.addEventListener('click', updateIdleTime, true)
    document.addEventListener('keypress', updateIdleTime, true)
    document.addEventListener('scroll', updateIdleTime, true)

    this.registerLoad = registerLeave
    this.registerLeave = registerLeave
    this.updateIdleTime = updateIdleTime
  }

  disconnect() {
    window.removeEventListener('beforeunload', this.registerLeave)
    window.removeEventListener('locationchange', this.registerLeave)

    document.removeEventListener('mousemove', this.updateIdleTime)
    document.removeEventListener('mousedown', this.updateIdleTime)
    document.removeEventListener('touchstart', this.updateIdleTime)
    document.removeEventListener('click', this.updateIdleTime)
    document.removeEventListener('keypress', this.updateIdleTime)
    document.removeEventListener('scroll', this.updateIdleTime, true)
  }
}
