import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["textarea"]
  static values = {
    formerSubmitText: String
  }
  static classes = ["card"]

  connect() {
    console.log("forms connect", this.element)
  }

  /**
   * Set height of textarea element to match height of content
   * can also use this one line solution on element if desired:
   * oninput "this.style.height = '';this.style.height = this.scrollHeight + 'px'"
   */
  setHeight(e) {
    const el = e.target
    el.style.height = ""
    return el.style.height = el.scrollHeight + 3 + "px"
  }

  /**
   * Gets the submit button for the form. Assumes a button of type submit, and only one per controller.
   */
  get submitButton() {
    return this.element.querySelector("button[type='submit']")
  }

  /**
   * Disable a form. It's possible there are multiple form elements per "forms" controller. (See e.g. forms.js)
   */
  disableSubmit() {
    const submitEl = this.submitButton
    this.formerSubmitTextValue = submitEl.innerHTML
    submitEl.disabled = true
    submitEl.innerHTML = "Uploading..."
  }

  /**
   * Enable a form.
   */
  enableSubmit() {
    const submitEl = this.submitButton
    submitEl.disabled = false
    submitEl.innerHTML = this.formerSubmitTextValue
  }
}