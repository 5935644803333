import {Controller} from "@hotwired/stimulus"
import {debounce} from "banzai/pendant/front/shared/util.js"
import {createFrameListener} from "banzai/pendant/front/util/turbo.js"

const AUTOCOMPLETE_RESULTS_HASH_DEFAULT = "results-default"

/*
 *  This controller is designed to be used in conjunction with the keyboard-list controller,
 *  which emits `handleSelectResult` events that can be handled with `selectResult`.
 */

function getAutocompleteHash(e) {
  const frame = e.detail.newFrame

  return frame ?
    frame.querySelector("[data-autocomplete-results-hash]").dataset.autocompleteResultsHash :
    AUTOCOMPLETE_RESULTS_HASH_DEFAULT
}

export default class extends Controller {
  static targets = ["results", "input", "optionalResultContainer"]
  static values = {
    autocompleteResultsHash: {type: String, default: AUTOCOMPLETE_RESULTS_HASH_DEFAULT},
  }

  connect() {
    console.log("autocomplete connect", this.element)

    this.autocomplete = debounce(this.autocomplete.bind(this), 150)

    createFrameListener(
      "turbo:before-frame-render",
      "send-results-frame",
      this.beforeAutocompleteRender.bind(this)
    )
  }

  /**
   * Request results for an autocomplete field. Debounces; see connect().
   */
  autocomplete(e) {
    if (e.key === "ArrowDown" || e.key === "ArrowUp" || e.key === "ArrowLeft" || e.key === "ArrowRight" ||
        e.key === "Meta" || e.key === "Shift" || e.key === "Control" || e.key === "Alt" || e.key === "Tab") {
      e.preventDefault() // Prevent unnecessary requests to the server
    } else {
      if (this.inputTarget.value.length > 0) {
        e.target.form.requestSubmit()
      } else {
        this.reset()
      }
    }
  }

  /**
   * Reset the autocomplete results to a zero state
   */
  reset() {
    if (this.hasInputTarget) this.inputTarget.value = ""
    if (this.hasResultsTarget) this.resultsTarget.innerHTML = ""
    this.autocompleteResultsHashValue = AUTOCOMPLETE_RESULTS_HASH_DEFAULT
  }

  /**
   * Compare the current autocomplete results hash to the new one. If they are the same, then
   * the results are the same and we should not render them again.
   * @param e
   */
  compareAndUpdateAutocompleteResults(e) {
    const newHash = getAutocompleteHash(e)

    if (this.autocompleteResultsHashValue === newHash) {
      e.detail.render = () => null
    }

    this.autocompleteResultsHashValue = newHash
  }

  beforeAutocompleteRender(e) {
    this.compareAndUpdateAutocompleteResults(e)
  }

  /**
   * Handles the selected result event
   * @param event
   */
  selectResult(event) {
    this.reset()
    this.optionalResultContainerTarget.innerHTML = ""

    // Since we don't really know what the user is going to do with the selected result, we
    // forward the target result along to a parent controller, as well as a reference to an optional
    // container that can be used to display the selected result.

    this.element.dispatchEvent(new CustomEvent("handleForwardResult", {
      detail: { // The `detail` key will carry the payload for your event
        result: event.detail.target,
        inputTarget: this.inputTarget,
        optionalResultContainer: this.optionalResultContainerTarget,
        resetFn: this.reset.bind(this)
      },
      bubbles: true // Necessary to make the event bubble to other controllers upstream in the DOM.
    }))
  }
}