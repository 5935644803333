import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["init"]

  connect() {
    console.log("popup connect", this.element)
  }

  get popupEl() {
    return document.getElementById(this.initTarget.dataset.id)
  }

  /*
   * Returns an array of class names
   */
  get popupClasses() {
    return this.initTarget.dataset.classes.split(" ")
  }

  /*
   * `msg` must be a string
   */
  popup(msg) {
    const msgEl = document.createElement("div")

    msgEl.innerText = msg
    msgEl.classList.add(...this.popupClasses)

    this.popupEl.append(msgEl)
  }

  popupHandler(event) {
    this.popup(event.detail.message)
  }
}
