import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        id: Number,
        type: String,
        title: String,
        subtitle: String,
        routeName: String,
        routeParams: Object
    }

    connect() {
        console.log("bookmark connect")
    }

    toggle() {
        fetch("/bookmarks/toggle", {
            method: 'POST',
            headers:{ 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'text/vnd.turbo-stream.html'
             },
            body: new URLSearchParams({
                id: this.idValue,
                type: this.typeValue,
                title: this.titleValue,
                subtitle: this.subtitleValue,
                routeName: this.routeNameValue,
                routeParams: JSON.stringify(this.routeParamsValue)
            })
        })
        .then(response => response.text())
        .then(data => {
            Turbo.renderStreamMessage(data)
        }).catch(err => {
            console.error("Error toggling bookmark: " + err)
        })
    }
}