import {Controller} from "@hotwired/stimulus"
import {createStreamListener} from "banzai/pendant/front/util/turbo.js"

function getWebsocketURL(relativePath) {
  const host = window.location.hostname;
  const port = window.location.port;
  const protocol = window.location.protocol === "https:" ? "wss" : "ws";

  return `${protocol}://${host}:${port}${relativePath}`;
}

export default class extends Controller {
  static targets = ["messageInput", "chatView", "emptyView"]

  static values = {
    submitUrl: String,
    getMessagesUrl: String
  }

  connect() {
    console.log("assitant connect", this.element)

    const controller = this;

    // Wait for the chat view to render, then scroll down.
    createStreamListener("pa-messages-container", "update", () => setTimeout(() => controller.onRenderMessages(), 100))

    this.ensureSocket()

    this.interval = setInterval(() => this.socket.send(JSON.stringify({"type": "ping"})), 5000)

    setTimeout(() => {
      // If the autofocus attribute has been set on the input then give it the
      // focus. This is needed so that the focus is applied after the modal is
      // opened.
      const autofocus = controller.messageInputTarget.getAttribute("autofocus")
      if (autofocus !== null) {
        controller.messageInputTarget.focus()
      }
    }, 100)

  }

  ensureSocket() {
    if (this.socket && this.socket.readyState == WebSocket.OPEN) {
      return
    }

    this.openSocket()
  }

  openSocket() {
    const controller = this;
    this.socketConnectionPromise = new Promise((resolve, reject) => {
      controller.socket = new WebSocket(
          getWebsocketURL("/ws/assistant?")
           + (controller.sessionId ? new URLSearchParams({ "session-id": controller.sessionId }) : "")
      )

      controller.socket.onopen = function(event) {
        console.log("WebSocket connection opened.")
        resolve()
      }

      controller.socket.onmessage = function(event) {
        const message = JSON.parse(event.data)

        if (message["type"] == "init") {
          controller.sessionId = message["session-id"]
        }

        if (message["type"] == "new-message") {
          controller.emptyViewTarget.classList.add("hidden")
          controller.updateView()
        }
      }

      controller.socket.onclose = function(event) {
        console.log("WebSocket connection closed.")
      }
    })
  }

  sendPrompt(event) {
    const prompt = event.target.dataset.prompt
    this.messageInputTarget.value = prompt
    this.sendMessage()
  }

  onRenderMessages() {
    const links = this.chatViewTarget.querySelectorAll('a');

    links.forEach(link => {
      link.setAttribute('target', '_blank');
    });

    this.scrollBottom()
  }

  scrollBottom(controller) {
    this.chatViewTarget.scrollTop = this.chatViewTarget.scrollHeight
  }

  clearMessageInput() {
    this.messageInputTarget.value = ""
    this.messageInputTarget.focus()
  }

  async sendMessage (event) {
    event && event.preventDefault();

    this.ensureSocket()
    await this.socketConnectionPromise

    this.socket.send(JSON.stringify({
      "type": "send-message",
      "message.form/content": this.messageInputTarget.value,
      "message.form/session-id": this.sessionId
    }))

    this.messageInputTarget.value = ''
    this.messageInputTarget.focus()
  }

  async updateView() {
    const res = await fetch(this.getMessagesUrlValue + "?" + new URLSearchParams({
      "session-id": this.sessionId
    }), {
      method: 'GET'
    })
    const data = await res.text()
    Turbo.renderStreamMessage(data);
  }

  disconnect() {
    this.socket.close()
  }
}
