import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["copyableLink"]

  connect() {
    console.log("input connect", this.element)
  }

  selectLink() {
    this.copyableLinkTarget.select()
    this.copyableLinkTarget.setSelectionRange(0, 99999)
  }
}
