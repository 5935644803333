/*
 * Contains helpers for interacting with Turbo.
 */

const addListener = (eventName, event) => {
  return document.documentElement.addEventListener(eventName, event)
}

/**
 * Creates a listener for any frame-specific event.
 */
export const createFrameListener = (eventName, formTargetId, newEvent) => {
  return addListener(eventName, (ev) => {
    if (ev.target.id === formTargetId) {
      return newEvent(ev)
    }
  })
}

/**
 * Create stream listener
 */
export const createStreamListener = (target, action, newEvent) => {
  return addListener("turbo:before-stream-render", (event) => {
    const attrs = event?.target?.attributes

    if (attrs?.target?.value === target && attrs?.action?.value === action) {
      return newEvent(event)
    }
  })
}