import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "name"]

  connect() {
    console.log("checkbox connect", this.element)
    this.publish()
  }

  /**
   * Triggered when a checkbox item is toggled.
   */
  toggle() {
    this.publish()
  }

  /**
   * Identifies the name of the checkbox group. Matches the name submitted by the form.
   * Passed in the `checkboxListChanged` event to distinguish between more than one checkbox list.
   * @returns {string}
   */
  get name() {
    if (this.hasNameTarget) {
      return this.nameTarget.dataset.name
    } else {
      return null
    }
  }

  /**
   * List of checked item targets.
   * @returns {*}
   */
  get checked() {
    return this.checkboxTargets.filter(ch => ch.checked)
  }

  /**
   * Counts the list of checked targets.
   * @returns {*}
   */
  get selectedCount() {
    return this.checked.length
  }

  /**
   * Returns the values of the checked targets.
   * @returns {*}
   */
  get selectedValues() {
    return this.checked.map(ch => ch.value)
  }

  /**
   * Publishes a `checkboxListChanged` event which can be consumed by other controllers.
   */
  publish() {
    this.element.dispatchEvent(new CustomEvent("checkboxListChanged", {
      detail: {
        name: this.name,
        selectedCount: this.selectedCount,
        selectedValues: this.selectedValues
      },
      bubbles: true // Necessary to make the event bubble to other controllers upstream in the DOM.
    }))
  }

  /**
   * Selects or de-selects every checkbox depending on `value`.
   * @param value
   */
  selectEvery(value, event) {
    this.checkboxTargets.forEach(ch => ch.checked = value)
    this.publish()
    event.preventDefault()
  }

  /**
   * De-selects all checkbox items.
   */
  selectNone(event) {
    this.selectEvery(false, event)
  }

  /**
   * Selects all checkbox items.
   */
  selectAll(event) {
    this.selectEvery(true, event)
  }
}
