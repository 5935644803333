import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["version"]

  connect() {
    console.log("page versions connect", this.element)
  }

  selectVersion(event) {
    this.versionTargets.forEach(target => {
      target.classList.remove("selected")
      target.classList.add("not-selected")
    })
    event.currentTarget.classList.add("selected")
    event.currentTarget.classList.remove("not-selected")
  }
}