import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "viewServicesToggleButton", "servicesSection" ]
  static classes = [ "servicesViewAll" ]

  connect() {
    console.log("benefits page connect", this)
  }

  viewAllToggle() {
    this.servicesSectionTarget.classList.toggle(this.servicesViewAllClass)

    this.viewServicesToggleButtonTarget.textContent =
      this.servicesSectionTarget.classList.contains(this.servicesViewAllClass) ? "Show Less" : "View All"
  }
  
}


