import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

  static values = {
    baseUrl: String,
    openChartBaseUrl: String,
    queryParams: String
  }

  static targets = ["date", "role", "promoteGroup"]

  connect() {
    console.debug("analytics filters connect", this.element)
  }

  queryParamsValueChanged(value, previousValue) {
    if (previousValue !== "") {
      Turbo.visit(`${this.baseUrlValue}?${this.queryParamsValue}`, {frame: 'analytics-graphs', action: 'advance'})
    }
  }

  roleFilterChange(event) {
    if (event.target.value !== "") {
      this.queryParamsValue = `${event.target.value}&${this.dateTarget.value}&${this.promoteGroupTarget.value}`
    } else {
      this.queryParamsValue = `${this.dateTarget.value}&${this.promoteGroupTarget.value}`
    }
  }

  dateFilterChange(event) {
    if (this.roleTarget.value !== "") {
      this.queryParamsValue = `${event.target.value}&${this.roleTarget.value}&${this.promoteGroupTarget.value}`
    } else {
      this.queryParamsValue = `${this.roleTarget.value}&${this.promoteGroupTarget.value}`
    }
  }

  promoteGroupFilterChange(event) {
    if (this.promoteGroupTarget.value !== "") {
      this.queryParamsValue = `${event.target.value}&${this.roleTarget.value}&${this.dateTarget.value}`
    } else {
      this.queryParamsValue = `${this.roleTarget.value}&${this.dateTarget.value}`
    }
  }

  openChartDetailsHandler({detail}) {
    const {chartType, seriesId} = detail

    Turbo.visit(
      `${this.openChartBaseUrlValue}?chart-type=${chartType}&id=${seriesId}&${this.queryParamsValue}`,
      {frame: 'shared-modal-frame'}
    )
  }
}