import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  /**
   * This controller provides a way to add a {class} to a {target} whenever the user scrolls
   * passed an {element}.
   * is scrolled by.
   *
   * Glossary
   * - {element}: data-sticky-header-passed-element
   *   Id of the element that the user will scroll passed. If not defined, uses the element of the controller.
   *
   * - {class}: data-sticky-header-passed-class
   *   Class name to apply to the target.
   *
   * - {target}: data-sticky-header-target
   *   Id of the element the class will be applied to. If not defined, uses the element of the controller.
   */

  connect() {
    console.log("sticky header connect", this.element)
    window.addEventListener("scroll", this.detectScroll.bind(this))
  }

  get passedTarget() {
    const e = this.element.dataset.stickyHeaderPassedTarget
    return e ? document.getElementById(e) : this.element
  }

  get passedElement() {
    const e = this.element.dataset.stickyHeaderPassedElement
    return e ? document.getElementById(e) : this.element
  }

  get passedElementPos() {
    const position = window.getComputedStyle(this.passedElement).position

    if (position === "sticky" || position === "fixed") {
      return this.passedElement.getBoundingClientRect().top + this.passedElement.offsetHeight
    } else {
      return this.passedElement.getBoundingClientRect().top + this.passedElement.offsetHeight + window.scrollY
    }
  }

  get passedClass() {
    const passedClass = this.element.dataset.stickyHeaderPassedClass

    if (!passedClass) {
      console.error("Make sure you set the data-sticky-header-passed-class attribute on the same element as the data-controller")
    } else {
      return passedClass
    }
  }

  detectScroll() {
    if (window.scrollY > this.passedElementPos) {
      this.passedTarget.classList.add(this.passedClass)
    } else {
      this.passedTarget.classList.remove(this.passedClass)
    }
  }

  disconnect() {
    window.removeEventListener("scroll", this.detectScroll.bind(this))
  }
}
