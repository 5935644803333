import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["notifyFrame"]
  static values = {
    groupIds: Array
  }

  connect() {
    console.log("announce connect", this.element)
  }

  get notifyUrl() {
    return this.notifyFrameTarget.dataset.url
  }

  groupIdsValueChanged(value) {
    if (this.hasNotifyFrameTarget) {
      if (value.length > 0) {
        this.notifyFrameTarget.src = `${this.notifyUrl}?${new URLSearchParams({groups: value})}`
      } else {
        this.notifyFrameTarget.innerHTML = ""
      }
    }
  }

  checkboxListChangedHandler(event) {
    if (event.detail.name === "announce.save.form/recipient-group-ids") {
      this.groupIdsValue = event.detail.selectedValues
    }
  }
}
