import {Controller} from "@hotwired/stimulus"

function scrollTop() {
  window.scrollTo({top: 0, behavior: 'smooth'})
}

export default class extends Controller {
  static targets = ["scrollUp", "tab", "tabContent", "searchModal", "navbar", "boundary", "boundaryShowTrigger", "homepageSearch"]

  connect() {
    console.log("nav connect", this.element)

    if (this.hasTabContentTarget) {
      const tabContent = this.tabContentTarget
      this.updateSelectedTabClass(tabContent.dataset.tabId)
    }


    if (this.hasSearchModalTarget) {
      const searchModal = this.searchModalTarget
      searchModal.querySelector("input").addEventListener("blur", function() {
        setTimeout(() => searchModal.classList.remove("show"), 100)
      })
    }

    this.addOnScroll()
  }

  selectTab(e) {
    this.updateSelectedTabClass(e.currentTarget.dataset.selectTabId)
  }

  updateSelectedTabClass(tabId) {
    this.tabTargets.forEach((tab) => {
      if (tab.dataset.selectTabId == tabId) {
        tab.classList.add("selected")
      } else {
        tab.classList.remove("selected")
      }
    })
  }

  addOnScroll() {
    const hasScrollUp = this.hasScrollUpTarget
    let scrollUpTarget

    if (hasScrollUp) {
      scrollUpTarget = this.scrollUpTarget
    }

    const hasBoundaryShowTriggerTarget = this.hasBoundaryShowTriggerTarget
    let boundaryTarget, navbar, boundaryShowTriggerTarget

    if (hasBoundaryShowTriggerTarget) {
      boundaryTarget = this.boundaryTarget
      navbar = this.navbarTarget
      boundaryShowTriggerTarget = this.boundaryShowTriggerTarget
    }

    function onScroll() {
      if (hasScrollUp) {
        if (window.scrollY > 100) {
          scrollUpTarget.classList.add("show")
        } else {
          scrollUpTarget.classList.remove("show")
        }
      }
      if (hasBoundaryShowTriggerTarget) {

        const boundaryTopShowTrigger = + navbar.offsetHeight
        if (boundaryShowTriggerTarget.getBoundingClientRect().top - navbar.offsetHeight <= 0) {
          boundaryTarget.classList.remove("invisible")
        } else {
          boundaryTarget.classList.add("invisible")
        }
      }
    }

    window.addEventListener("scroll", onScroll)
    onScroll()
  }

  scrollTop() {
    scrollTop()
  }

  back(event) {
    window.history.back()
    event.preventDefault()
  }

  confirm(event) {
    if (!confirm(event.target.dataset.confirmMessage)) {
      event.preventDefault()
    }
  }

  searchFocusHandler(event) {
    this.toggleSearchModal(event)
  }

  toggleSearchModal(event) {
    if (this.hasHomepageSearchTarget) {
      this.homepageSearchTarget.querySelector("input").focus()
    } else {
      this.searchModalTarget.classList.toggle("show")
      this.searchModalTarget.querySelector("input").focus()
    }
  }

}
