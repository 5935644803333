import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["output"]

  connect() {
    console.log("groups counter connect", this.element)
  }

  handler(event) {
    const n = event.detail.name

    if (n === "benefits" || n === "users") {
      this.outputTarget.innerHTML = event.detail.selectedCount
    }
  }
}
