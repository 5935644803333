import {Controller} from "@hotwired/stimulus"
import {StreamActions} from "@hotwired/turbo"
import MicroModal from "micromodal"
import {openModal} from "banzai/pendant/front/util/modals.js"
import {createFrameListener} from "banzai/pendant/front/util/turbo.js"

export default class extends Controller {
  static targets = ["modal"]

  connect() {
    console.log("modal connect", this.element)

    this.modalTargets.forEach(modalTarget => {
      createFrameListener(
        "turbo:frame-render",
        modalTarget.id,
        openModal(modalTarget)
      )

      StreamActions.removeModal = function () {
        const id = this.dataset.modalId

        MicroModal.close(id)
        document.getElementById(id).remove() // It wasn't closing on its own, so I'm forcing it to close and then removing it from the DOM.
      }
    })

    MicroModal.init({disableScroll: true})
  }

  close(event) {
    MicroModal.close(event.currentTarget.dataset.modalId)
  }
}
