/**
 * General-purpose debounce function for help with e.g. autocomplete
 * @param func
 * @param timeout
 * @returns {(function(...[*]): void)|*}
 */
export function debounce(func, timeout = 300) {
  let timer
  return (...args) => {
    const context = this
    clearTimeout(timer)
    timer = setTimeout(() =>  func.apply(context, args), timeout)
  }
}