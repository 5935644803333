import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static values = { 
        questionId: String,
        options: Array      /* ex. [0, 1, 2] */
    }

    nextId() {
        let id = Math.max.apply(null, this.optionsValue) + 1
        
        let newOptions = this.optionsValue
        newOptions.push(id)

        this.optionsValue = newOptions

        return id;
    }

    addOption() {
        let id = this.nextId()

        fetch(`/questions/multiple-choice-add-option?id=${this.questionIdValue}&type=text&optionId=${id}`)
        .then(response => response.text())
        .then(html => Turbo.renderStreamMessage(html))
    }

    removeOption({ params }) {
        document.getElementById(params.id).remove()
    }
}