import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["handle", "link", "primaryBrandColor"]

  connect() {
    console.log("org connect", this.element)
  }

  toggle(event) {
    this.handleTargets.forEach(handle => {
      handle.classList.toggle("hidden")
    })
  }
}
