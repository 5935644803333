import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["theText"]

  connect() {
    // console.log("button connect", this.element)
  }

  setOnClickText(event) {
    const target = event.currentTarget
    this.theTextTarget.innerHTML = target.dataset.onClickText
  }
}