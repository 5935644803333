import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    id: String,
    options: Object,
    detailsType: String,
    detailsId: String
  }

  Chart = () => {
    this.chart = window.echarts.init(document.getElementById(this.idValue), null, {renderer: 'svg'})
    this.chart.setOption(this.optionsValue)

    //BUG: for some reason when the chart loads in the modal it defaults to 100px even though the container is larger
    //so this is a small hack until I can look further into what's going on here.
    window.setTimeout(this.chart.resize, 100)

    if (this.detailsIdValue !== "") {
      this.chart.on("click", this.detailsTypeValue === "" ? "series" : this.detailsTypeValue, this.openChartDetails.bind(this))
    }
  }

  openChartDetails(params) {
    this.element.dispatchEvent(new CustomEvent("openChartDetails", {
      detail: {...params, chartType: this.detailsIdValue},
      bubbles: true
    }))
  }

  initChart = () => {
    if (window.echarts !== undefined) {
      this.Chart()

      if (window.addEventListener) {
        window.addEventListener("resize", this.chart.resize)
      }
    } else {
      window.setTimeout(this.initChart, 1000)
    }
  }

  initialize() {
    this.initChart()
  }
}