import {Controller} from "@hotwired/stimulus"
import {createFrameListener} from "banzai/pendant/front/util/turbo.js"

function clearTarget(target) {
  target.remove()
}

function clearAlert(alertTarget, hasNotificationTarget) {
  if (hasNotificationTarget === false) {
    clearTarget(alertTarget)
  }
}

export default class extends Controller {
  static targets = ["alert", "readNotification"]

  connect() {
    // console.log("notifications connect", this.element)

    const self = this
    const _clearAlert = () => {
      if (self.hasAlertTarget) {
        clearAlert(self.alertTarget, self.hasReadNotificationTarget)
      }
    }

    createFrameListener("turbo:frame-render", "flyout-notification-inner", _clearAlert)
  }
}
