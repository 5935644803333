import { Controller } from "@hotwired/stimulus";
import dragula from "dragula"

export default class extends Controller {
  static targets = ["rearrangeableContainer", "autoSubmitForm"];

  connect() {
    console.log("rearrangeable_list connect", this.element)

    this.drake = dragula({containers: this.rearrangeableContainerTargets,
                          invalid: (_el, h) => !h.classList.contains("cursor-grab")})

    this.drake.on('drop', () => 
    console.log(this.autoSubmitFormTargets)  &&  
    setTimeout(() => // give dragula a tick to remove the "mirror" element so that the dragged input isn't duplicated.
        this.autoSubmitFormTargets.forEach(form => form.requestSubmit())))
  }

  disconnect() {
    this.drake?.destroy()
  }
}
