import {Controller} from "@hotwired/stimulus"
import { useClickOutside } from 'stimulus-use'
import { DateTime } from "luxon"

function isToday(dateTime) {
  return dateTime.startOf("day").diff(DateTime.now().startOf("day"), 'days').days == 0
}

export default class extends Controller {

  static values = {
    locale: { "type": String, "default": "en-US" },

    // Custom formatter, or any from https://moment.github.io/luxon/#/formatting?id=presets
    formatter: { "type": String, "default": "DATETIME_SHORT" },

    unixTimestamp: Number,
  }

  connect() {
    this.element.innerHTML = this.getDisplayValue()
  }

  getDisplayValue() {
    const formatter = this.formatterValue
    const dateTime = this.getDateTime()

    switch(formatter) {
      case  "date-at-time":
        if (isToday(dateTime)) {
          return `Today at ${dateTime.toLocaleString(DateTime.TIME_SIMPLE)}`
        } else {
          return `on ${dateTime.toLocaleString(DateTime.DATETIME_SHORT)}`
        }

      case  "date-spelled-out":
        if (isToday(dateTime)) {
          return `Today at ${dateTime.toLocaleString(DateTime.TIME_SIMPLE)}`
        } else {
          return `${dateTime.toLocaleString(DateTime.DATE_FULL)}`
        }

      case "date-plain-short":
        return dateTime.toLocaleString({
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric"
        })

      case "time-ago":
        if (DateTime.now().diff(dateTime, 'seconds').toObject().seconds < 30) {
          return 'just now'
        } else {
          return dateTime.toRelative({ base: DateTime.now() })
        }

      default:
        return dateTime.toLocaleString(DateTime[formatter])
    }
  }

  getDateTime() {
    return DateTime.fromMillis(this.unixTimestampValue).setLocale(this.getLocale())
  }

  getTimezone() {
    return this.timezoneValue || Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  getLocale() {
    return this.localeValue || navigator.language
  }

}
