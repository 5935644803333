import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["hiddenUserIdInput"]

  connect() {
    console.log("send connect", this.element)
  }

  /**
   * Handler catches the handleForwardResult event. Takes the result element, adds it to the
   * container, gives it a close button, and when clicked, reverses it all. Hyperspecific logic,
   * but it's local to this usecase.
   * @param e
   */
  selectUser(e) {
    setTimeout((() => { // I have no idea why the delay was necessary, but Safari simply wouldn't work without it. You could step through each line and it would work, but if not, the result wouldn't appear in the container.
      const result = e.detail.result.cloneNode(true)
      const container = e.detail.optionalResultContainer
      const input = e.detail.inputTarget
      const resetFn = e.detail.resetFn
      const hiddenInputTarget = this.hiddenUserIdInputTarget
      let placeholderPlaceholder = input.placeholder

      input.disabled = true
      input.placeholder = ""

      const containerClassList = [
        "flex",
        "justify-between",
        "items-center",
        "hover:cursor-pointer",
        "w-full",
        "mt-3",
        "pr-3"
      ]

      container.classList.add(...containerClassList)

      result.classList.remove(
        "transition-all",
        "hover:ease-on-hover",
        "hover:bg-gray-8",
        "pt-1.5",
        "keyboard-list-item-active"
      )

      result.dataset.keyboardListTarget = undefined

      container.appendChild(result)

      const button = document.createElement("button")
      button.ariaLabel = "Close"
      button.className = "bg-inherit p-[.188rem] leading-[0]"
      button.datasetMicromodalClose = "true"

      const icon = document.createElement("i")
      icon.ariaHidden = "true"
      icon.className = "pd-icon text-small1 icon-cancel"
      icon.datasetMicromodalClose = "true"
      icon.size = "small1"
      icon.type = "cancel"

      button.appendChild(icon)

      container.appendChild(button)

      container.addEventListener("click", () => {
        container.innerHTML = ""
        input.disabled = false
        input.placeholder = placeholderPlaceholder
        input.focus()
        hiddenInputTarget.value = ""
        resetFn()
      })

      // Update the hidden input on the form
      hiddenInputTarget.value = result.dataset.id
    }).bind(this), 1)
  }
}
