import {Controller} from "@hotwired/stimulus"

function getPaneById(paneId) {
  return document.getElementById(paneId)
}

function getPaneByTab(tab) {
  return document.getElementById(tab.dataset.paneId)
}

export default class extends Controller {
  static targets = ["tab", "pane"]
  static classes = ["selected", "hidden"] // https://stimulus.hotwired.dev/reference/css-classes

  connect() {
    console.log("tab-editor connect", this.element)

    // All panes start hidden; show the selected
    this.showSelectedPanes()
  }

  clearInputs() {
    this.paneTargets.forEach(pane => {
      pane.querySelectorAll("input, select").forEach(input => {
        input.value = ""
      })
    })
  }

  showPane(pane) {
    pane.classList.remove(this.hiddenClass)
  }

  hidePane(pane) {
    pane.classList.add(this.hiddenClass)
  }

  selectTab(tab) {
    tab.classList.add(this.selectedClass)
  }

  deselectTab(tab) {
    tab.classList.remove(this.selectedClass)
  }

  showSelectedPanes() {
    this.tabTargets.forEach(tab => {
      if (tab.dataset.hasOwnProperty("selected")) { // if has data-selected attr
        this.selectTab(tab)
        this.showPane(getPaneByTab(tab))
      }
    })
  }

  hideAllPanes() {
    this.paneTargets.forEach(pane => this.hidePane(pane))
  }

  deselectAllTabs() {
    this.tabTargets.forEach(tab => {
      this.deselectTab(tab)
    })
  }

  onTabClick(event) {
    const tab = event.currentTarget

    this.hideAllPanes()
    this.showPane(getPaneById(tab.dataset.paneId))

    this.deselectAllTabs()
    this.clearInputs() // Clears values of inputs whenever a tab is selected
    this.selectTab(tab)

    event.preventDefault()
  }
}