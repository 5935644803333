import {Controller} from "@hotwired/stimulus"

/*
 * Make sure this controller is attached to a very top-level DOM element.
 */

export default class extends Controller {
  static targets = ["searchInput"]

  connect() {
    console.log("shortcuts connect", this.element)

    this.listen()
  }

  /**
   * Set up a listener designed for the entire app.
   */
  listen() {
    document.addEventListener("keydown", event => {
      if (event.target.contentEditable !== "true"
          && !event.target.classList.contains("trix-input")) {
        switch (event.key) {
          case "/":
            this.focusOnSearchInput(event)
        }
      }
    })
  }

  /**
   * Move focus to the search input, stopping the key event.
   * @param event
   */
  focusOnSearchInput(event) {
    this.element.dispatchEvent(new CustomEvent("triggerSearchFocus", {bubbles: true}))
    event.preventDefault()
  }
}
