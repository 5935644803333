import {Controller} from "@hotwired/stimulus"
import {createFrameListener} from "banzai/pendant/front/util/turbo.js"

function onNewCommentScroll(target) {
  const box = target.getBoundingClientRect()

  if (box.height < window.innerHeight) { // When the screen is simply too squished, don't do anything
    const newTop = window.scrollY + (box.top + box.height - window.innerHeight)

    if (newTop > window.scrollY) { // Make sure the scroll position moves down, not up
      window.scrollTo({top: newTop, behavior: "smooth"})
    }
  }
}

// Add hotkeys to the submit button
function listenForShortcuts(buttonTarget, editorTarget) {
  editorTarget.addEventListener("keydown", (event) => {
    if (event.key === "Enter" && (event.getModifierState("Control") || event.getModifierState("Meta"))) {
      event.preventDefault()
      buttonTarget.click()
    }
  })
}

// Prevent the form from submitting without content
function preventNoContent(formTarget, contentTarget) {
  formTarget.addEventListener("submit", (event) => {
    if (contentTarget.value === "") {
      event.preventDefault()
      return false
    }
  })
}

export default class extends Controller {
  static targets = ["newComment", "addCommentSubmit", "content", "submitContent", "editor"]

  connect() {
    console.log("discuss connect", this.element)

    this.highlightComment()

    const self = this

    createFrameListener(
      "turbo:frame-render",
      "new-comment",
      () => {
        listenForShortcuts(self.addCommentSubmitTarget, self.editorTarget)
        onNewCommentScroll(self.newCommentTarget)
        preventNoContent(self.submitContentTarget, self.contentTarget)
      }
    )

  }

  highlightComment() {
    const hash = window.location.hash
    if (hash) {
      const commentHash = hash.replace("anchor-", "")
      const comment = document.getElementById(commentHash.slice(1))
      comment.classList.add("highlight-comment")
    }
  }
}


