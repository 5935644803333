import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["notifyFrame"]
    
    static values = {
      groupIds: Array
    }

    get notifyUrl() {
        return this.notifyFrameTarget.dataset.url
    }

    groupIdsValueChanged(value) {
        if (this.hasNotifyFrameTarget) {
            if (value.length > 0) {
              this.notifyFrameTarget.src = `${this.notifyUrl}?${new URLSearchParams({groups: value})}`
            } else {
              this.notifyFrameTarget.innerHTML = ""
            }
        }
    }

    checkboxListChangedHandler(evt) {
        if (evt.detail.name === "survey-group-ids") {
            this.groupIdsValue = evt.detail.selectedValues
        }
    }     
}