import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["copy"]

  connect() {
    console.log("copy connect", this.element)
  }

  copy(event) {
    navigator.clipboard.write([
      new ClipboardItem({
        "text/plain": new Blob([this.copyTarget.dataset.copyContents], {type: "text/plain"}),
        "text/html": new Blob([this.copyTarget.dataset.copyHtmlContents], {type: "text/html"}),
      })
    ])

    this.element.dispatchEvent(new CustomEvent("triggerPopup", {
      detail: { // The `detail` key will carry the payload for your event
        message: this.copyTarget.dataset.copyPopupMessage
      },
      bubbles: true // Necessary to make the event bubble to other controllers upstream in the DOM.
    }))
    event.preventDefault()
  }
}
