import {install} from '@github/hotkey'
import {Controller} from "@hotwired/stimulus"
import MicroModal from "micromodal"
import {createFrameListener, createStreamListener} from "banzai/pendant/front/util/turbo.js"
import {openModal} from "banzai/pendant/front/util/modals.js"

function scrollTo(top) {
  if (window.scrollY > top) {
    window.scrollTo({top: top})
  }
}

function scrollToTop() {
  scrollTo(0) // This is to overcome a limitation in Turbo wherein data-autoscroll-block doesn't get the page all the way to the top
}

function focusAfterRender(selector, relToElement) {
  return () => setTimeout(() => {
    if (!relToElement) relToElement = document.documentElement
    var el = relToElement.querySelector(selector)
    if (el) el.focus(); else console.warn("Not found; cannot focus", selector);
  }, 50)
}

// Add hotkeys to a submit button
function installHotkeys() {
  for (const el of document.querySelectorAll('[data-hotkey]')) {
    install(el)
  }
}

/**
 * Respond to an updated passage
 */
function handlePassageSaved(foldTarget, event) {
  scrollToTop()
}

/**
 * Filter out unnecessary updates to the passages being updated.
 */
function handleUpdatedPassage(foldTarget) {
  return function (event) {
    const trixContentEl = [...event.target.templateContent.childNodes]?.filter(child => {
      return child.matches(".trix-content")
    })[0]

    if (trixContentEl) {
      handlePassageSaved(foldTarget, event)
    }
  }
}

export default class extends Controller {
  static targets = ["fold", "preview", "copyLinkMenu"]

  connect() {
    console.log("benefit connect", this.element)

    createStreamListener("Passage", "replace", handleUpdatedPassage(this.foldTarget))

    createFrameListener("turbo:before-frame-render", "benefit-body", scrollToTop)
    createFrameListener("turbo:frame-render", "benefit-body", this.showAdminOnHover)

    createFrameListener(
      "turbo:submit-end",
      "FAQ-add-question",
      focusAfterRender(".faqs li:last-child .faq-question input"))
    createFrameListener(
      "turbo:submit-end",
      "Points-add-point",
      focusAfterRender(".Points-editor li:last-child .point-text input"))

    this.showAdminOnHover()

    if (this.hasPreviewTarget) {
      createFrameListener(
        "turbo:frame-render",
        "attachment-preview",
        openModal(this.previewTarget)
      )
    }

    createFrameListener(
      "turbo:frame-render",
      "FAQs-frame",
      () => {
        installHotkeys()
      })

    createFrameListener(
      "turbo:frame-render",
      "Points-frame",
      () => {
        installHotkeys()
      })

    if (document.querySelector("#edit-benefit-form, #Passage-update")) installHotkeys();
    createFrameListener(
      "turbo:frame-render",
      "edit-benefit-form",
      () => {
        console.log("edit-benefit-form")
        installHotkeys()
      })

    createFrameListener(
      "turbo:before-stream-render",
      "",
      () => {
        setTimeout(() => installHotkeys(), 50)
      })

    MicroModal.init({disableScroll: true})
  }

  showAdminOnHover() {
    const admins = document.getElementById("your-admins")
    const adminsTooltip = document.getElementById("admins-tooltip")
    admins && admins.addEventListener("mouseover", () => {
      adminsTooltip.classList.add("show")
    })
    admins && admins.addEventListener("mouseout", () => {
      adminsTooltip.classList.remove("show")
    })
  }

  copyAnchorUrl(e) {
    const message = document.getElementById("faq-copy-message")

    if (!navigator.clipboard) {
      // Clipboard API not available
      return
    }

    const location = window.location
    const anchorUrl = `${location.origin}${location.pathname}#${e.currentTarget.getAttribute("data-anchor")}`

    try {
      navigator.clipboard.writeText(anchorUrl)
      message.classList.add("show")
      setTimeout(() => message.classList.remove("show"), 5000)
    } catch (err) {
      console.error('Failed to copy.', err)
    }
  }
}