import {install} from '@github/hotkey'
import {Controller} from "@hotwired/stimulus"
import {createFrameListener, createStreamListener} from "banzai/pendant/front/util/turbo.js"

// Add hotkeys to a submit button
function installHotkeys() {
  for (const el of document.querySelectorAll('[data-hotkey]')) {
    install(el)
  }
}
  
export default class extends Controller {
  static targets = []

  connect() {
    installHotkeys()
    createFrameListener(
        "turbo:frame-render",
        "FAQs-frame",
        () => {
          installHotkeys()
        })
  }
}
