import {Controller} from "@hotwired/stimulus"

function handleServiceTypeChange(controller) {
  return function(ev) {
    const typeId = controller.serviceTypeTarget.value
    const serviceType = controller.typesValue.find((serviceType) => serviceType.id == typeId)
    if (serviceType) {
      controller.descriptionTarget.placeholder = serviceType["placeholder-text"];
    }
  }
}

export default class extends Controller {
  static targets = [ "serviceType", "description" ]
  static classes = [ "servicesViewAll" ]

  static values = {
    types: Array
  }
  
  connect() {
    this.serviceTypeTarget.addEventListener("change", handleServiceTypeChange(this))
    handleServiceTypeChange(this)()
  }

  disconnect() {
    this.serviceTypeTarget.removeEventListener("change", handleServiceTypeChange(this))
  }
  
}

