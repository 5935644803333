import {Controller} from "@hotwired/stimulus"
import {debounce} from "banzai/pendant/front/shared/util.js"

export default class extends Controller {
  static targets = ["input", "flyout", "flyoutFrame"]

  connect() {
    console.log("search connect", this.element)

    this.clear() // Reset when the controller is loaded for back and forth navigation
    this.listen()

    this.search = debounce(this.search.bind(this), 200)
  }

  /**
   * Listens for key strokes, keeping the experience in line with the user's expectations
   * for how an auto-complete search should work.
   */
  listen() {
    this.inputTarget.addEventListener('keydown', e => {
      switch (e.key) {
        case "ArrowDown":
          e.preventDefault() // Stop the cursor from jumping to the end of the input box
          break
        case "ArrowUp":
          e.preventDefault() // Stop the cursor from jumping to the beginning of the input box
          break
        case "Tab":
          this.clear() // Clear the search bar
          break
      }
    })
  }

  /**
   * Performs the search query by updating the <turbo-frame> element's `src` property.
   * @param event
   */
  search(event) {
    const target = event.target
    const query = target.value
    const ft = this.flyoutTarget

    if (query !== "") {
      ft.open = true // Open the flyout to show "Searching..." upon the first search

      // Changing the src of a <turbo-frame> element will make a server request
      this.flyoutFrameTarget.src = `${target.dataset.route}?${new URLSearchParams({query})}`
    } else {
      this.clear() // Close the flyout
    }

    // Bubble up an event to other controllers that a search has been initiated
    this.element.dispatchEvent(new CustomEvent("search", {bubbles: true}))
  }

  /**
   * Clears the flyout.
   */
  clear() {
    this.inputTarget.value = ""
    this.flyoutTarget.open = false
  }

  /**
   * Navigates to the desired search result.
   */
  go(event) {
    const target = event.detail.target

    event.preventDefault()

    // Click whatever in the option appears to be or has an <a> tag. It seems to be fine to click both.
    target.click?.()
    target.querySelector("a").click?.()
  }
}